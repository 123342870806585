
import { defineComponent } from "vue";
import { IonText } from "@ionic/vue";

export default defineComponent({
  name: "CampaignCard",

  components: { IonText },

  props: {
    campaign: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isOpenPopoverMenuPkg: false,
      eventPopoverMenuPkg: null,
    };
  },
});
