import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card bg-light cursor-pointer",
  style: {"overflow":"hidden","height":"9rem"}
}
const _hoisted_2 = { class: "card-body p-2" }
const _hoisted_3 = { class: "flex w-full" }
const _hoisted_4 = { class: "w-full ml-2" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "text-small" }
const _hoisted_7 = {
  class: "mt-2",
  style: {"display":"-webkit-box","max-width":"200px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical","overflow":"hidden","font-size":"14px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: "rounded-md bg-light bg-no-repeat bg-center",
            style: _normalizeStyle([{"width":"8rem","height":"8rem"}, { backgroundImage: 'url(' + _ctx.campaign.gambar + ')' }])
          }, null, 4)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_text, { color: "dark" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.campaign.judul), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.campaign.created_c), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$filters.htmlRemove(_ctx.campaign.deskripsi)), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}