
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonText, toastController } from '@ionic/vue'
import CampaignCard from '@/components/functional/campaign/CampaignCard.vue'
import axios from "axios";

export default defineComponent ({
  name: 'Campaign',

  components: { IonPage, IonContent, IonText, CampaignCard },

  data () {
    return {
      campaigns: [
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        },
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        },
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        },
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        },
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        },
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        },
        {
          title: 'SESAMA GO TO STREET',
          img: 'https://lorempixel.com/128/112/food',
          date: '25 September 2021',
          description: 'Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Facilis, nulla laborum mollitia ratione.'
        }
      ]
    }
  },
  async ionViewDidEnter() {
    await axios.get("campaign").then(({ data }) => {
        this.campaigns = data.data
      }).catch(async ({response}) => {
        const toast = await toastController.create({
          message: response.data.message,
          duration: 2000,
        })
        return toast.present()
      });
  }
})
